import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Colors from "../Colors";
import MyDocument from "../../BookingService/BookingPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import assets from "../../assets";

const { "Playzo (1).svg": logo } = assets;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#000333",
  borderRadius: "15px",
  p: 5,
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  text: string;
  headingText: string;
  paymentText?: string;
  pdf?: any;
  data?: any;
  finalAmount?: any;
  applyValue?: any;
  payValue?: any;
  discountAmount?: any;
  StoredAmount?: any;
}

export default function ModalComponent({
  open,
  handleClose,
  text,
  headingText,
  paymentText,
  pdf,
  data,
  finalAmount,
  payValue,
  applyValue,
  discountAmount,
  StoredAmount,
}: ModalProps) {
  const handleBackdropClick = (event: any) => {
    event.stopPropagation();
  };

  const [localPaymentType, setLocalPaymentType] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      const storedPaymentType = localStorage.getItem("paymentType");
      if (storedPaymentType) {
        console.log(
          "Setting payment type from local storage",
          storedPaymentType
        );
        setLocalPaymentType(storedPaymentType);
      }
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ onClick: handleBackdropClick }}
    >
      <Box
        width={{ xs: "70%", sm: "70%", md: "100%" }}
        maxWidth={pdf ? 400 : 300}
        sx={style}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <img width={150} height={50} src={logo} alt="alterknit" />
          <Typography
            textAlign={"center"}
            fontSize={"20px"}
            color={Colors.WHITE}
            fontWeight={600}
            fontFamily={"Montserrat, sans-serif"}
          >
            {headingText}
          </Typography>
          <Typography
            textAlign={"center"}
            fontSize={"17px"}
            color={Colors.WHITE}
            fontFamily={"Montserrat, sans-serif"}
          >
            {text}
            <br />
          </Typography>
          {localPaymentType === "advance" && paymentText && (
            <Typography
              border={"1px solid white"}
              textAlign={"center"}
              textTransform={"none"}
              fontSize={"15px"}
              fontFamily={"Montserrat, sans-serif"}
              padding={"10px"}
              color={Colors.WHITE}
            >
              {paymentText}
            </Typography>
          )}
          <Stack
            direction={"row"}
            gap={"1rem"}
            justifyContent={{ xs: "center", sm: "center", md: "space-between" }}
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              sx={{
                padding: "8px 20px",
                textTransform: "none",
                fontSize: "14px",
                maxWidth: { xs: "100%", sm: "100%", md: "150px" },
                fontWeight: "400",
                border: "2px solid #15B5FC",
                borderRadius: "30px",
                letterSpacing: "1.6px",
                background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                color: Colors.WHITE,
                fontFamily: "Montserrat, sans-serif",
                ":hover": {
                  background: Colors.WHITE,
                  color: Colors.BUTTON_COLOR,
                  border: "2px solid #15B5FC",
                },
              }}
              onClick={handleClose}
            >
              OK
            </Button>

            {pdf ? (
              <Button
                variant="outlined"
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "14px",
                  maxWidth: "350px",
                  fontWeight: "400",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                  color: Colors.WHITE,
                  fontFamily: "Montserrat, sans-serif",
                  // whiteSpace: "nowrap",
                  ":hover": {
                    background: Colors.WHITE,
                    color: Colors.BUTTON_COLOR,
                    border: "2px solid #15B5FC",
                  },
                }}
              >
                <div>
                  <PDFDownloadLink
                    style={{
                      color: "red",
                    }}
                    document={
                      <MyDocument
                        paymentType={localPaymentType}
                        applyValue={applyValue}
                        payValue={payValue}
                        finalAmount={finalAmount}
                        allBookings={data}
                        discountAmount={discountAmount}
                        StoredAmount={StoredAmount}
                      />
                    }
                    fileName="booking.pdf"
                  >
                    Download Booking Receipt
                  </PDFDownloadLink>
                </div>
              </Button>
            ) : (
              ""
            )}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
