/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import AppContainer from "../CommonComponents/AppContainer";
import BookingParantmodal from "../BookingService/BookingParantmodal";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import Colors from "../CommonComponents/Colors";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Juice from "../assets/juices & dinner.pdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoginForm from "../pages/login/LoginForm";
import { Logout } from "@mui/icons-material";
import NonVegPdfMenu from "../assets/Zo's cafe non-veg.pdf";
import PersonIcon from "@mui/icons-material/Person";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import UserLoginApi from "../api/UserLoginApi";
import VegPdfMenu from "../assets/Zo's-cafe-veg.pdf";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import assets from "../assets";
import routes from "../routes/routes";

const { "Playzo (1).svg": logo } = assets;

export default function Header() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openvalue = Boolean(anchorEl);

  const [shrinkHeader, setShrinkHeader] = useState(false); // State for header shrink

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    navigate(routes.USERPROFILE);
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmLogout = async () => {
    try {
      await UserLoginApi.logoutUser();

      localStorage.clear();
      setIsLoggedIn(false);
      setAnchorEl(null);

      window.location.href = routes.ROOT;
    } catch {
      console.log("Logout failed");
    }
  };

  const handleLogout = async (event: any) => {
    event.preventDefault();

    // Check if local storage is empty
    const isLocalStorageEmpty =
      localStorage.getItem("bookings") == null ||
      localStorage.getItem("bookings") === undefined ||
      localStorage.getItem("bookings") === "[]";

    if (!isLocalStorageEmpty) {
      setOpenModal(true); // Open the modal instead of using window.confirm
      return;
    }

    try {
      // Logout user
      await UserLoginApi.logoutUser();

      localStorage.clear();
      setIsLoggedIn(false);
      setAnchorEl(null);

      window.location.href = routes.ROOT;
    } catch {
      console.log("Logout failed");
    }
  };

  interface User {
    name?: string;
  }

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const parsedUserData = userData && JSON.parse(userData);

    if (parsedUserData) {
      setUser(parsedUserData);
      setIsLoggedIn(true); // Set to true if there is user data
    } else {
      setUser(null);
      setIsLoggedIn(false); // Set to false if there is no user data
    }
  }, []);

  const location = useLocation();
  const currentRoute = location.pathname;
  const disabledRoutes = [
    routes.TURF_BOOKING,
    routes.BOOKING_SERVICE,
    routes.PLAYSTATION_BOOKING,
    routes.BOARDGAME_BOOKING,
    routes.BADMINTON_BOOKING,
    routes.BOWLINGMACHINE_BOOKING,
    routes.CRICKETNET_BOOKING,
  ];
  // const isDisabled = disabledRoutes.includes(currentRoute);

  const handleButtonClick = () => {
    // Replace the phone number with the desired one
    const phoneNumber = "7094460944";
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const handleBookNowClick = () => {
    if (isLoggedIn) {
      // If logged in, navigate to the booking service page
      navigate(routes.BOOKING_SERVICE);
    } else {
      // If not logged in, open the login modal
      handleOpen();
    }
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 100) {
  //       setShrinkHeader(true); // Shrink header when scrollY > 100
  //     } else {
  //       setShrinkHeader(false); // Reset header when at the top
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll); // Cleanup event listener
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setShrinkHeader(scrollTop > 50); // Adjust the threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [menuzos, setMenuZos] = useState(null);

  const handleMenuClick = (event: any) => {
    setMenuZos(event.currentTarget);
  };

  const handleFoodMenuClose = () => {
    setMenuZos(null);
  };

  const handleNavigate = (type: any) => {
    try {
      const vegPdfUrl = "path/to/veg.pdf"; // Add your actual PDF path
      const nonVegPdfUrl = "path/to/non-veg.pdf"; // Add your actual PDF path
      const url = type === "veg" ? vegPdfUrl : nonVegPdfUrl;
      window.open(url, "_blank");
    } catch (error) {
      console.error("Failed to navigate to the PDF", error);
    }
    handleFoodMenuClose();
  };

  return (
    <Box
      display={{ xs: "none", sm: "none", md: "flex", lg: "flex" }}
      sx={{
        backgroundColor: Colors.BLACK,
        transition:
          "height 0.5s ease-in-out, background-color 0.5s ease-in-out",
        height: shrinkHeader ? "70px" : "100px", // Header height adjusts smoothly
        alignItems: "center",
        justifyContent: "space-between",
        // position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000, // Ensure header stays on top
        boxShadow: shrinkHeader ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none", // Optional shadow on scroll
      }}
    >
      <AppContainer maxWidth={1200}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          paddingY={3}
        >
          <Box>
            <Link to={routes.ROOT}>
              <img
                style={{
                  height: shrinkHeader ? "40px" : "80px", // Smooth logo size transition
                  transition: "height 0.5s ease", // Logo size transition
                }}
                width={200}
                src={logo}
                alt="playzo33"
              />
            </Link>
          </Box>

          <Stack direction="row" spacing={3} alignItems="center">
            <Link to={routes.ABOUTUS}>
              <Typography
                sx={{
                  color: Colors.WHITE,
                  transition: "color 0.5s ease",
                  textTransform: "uppercase",
                  ":hover": {
                    color: Colors.BUTTON_COLOR,
                  },
                }}
                fontFamily={"brandonText"}
                fontWeight={"600"}
                fontSize="15px"
                letterSpacing={"1.6px"}
              >
                About Us
              </Typography>
            </Link>
            {/* <DropDown /> */}

            <Link className="react-router-link" to={routes.CONTACTUS}>
              <Typography
                sx={{
                  color: Colors.WHITE,
                  transition: "color 0.5s ease",
                  textTransform: "uppercase",
                  ":hover": {
                    color: Colors.BUTTON_COLOR,
                  },
                }}
                fontFamily={"brandonText"}
                fontWeight={"600"}
                fontSize="15px"
                letterSpacing={"1.6px"}
              >
                Contact Us
              </Typography>
            </Link>

            <Box>
              <Box
                onClick={handleMenuClick}
                display={"flex"}
                gap={"5px"}
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  fontFamily={"Montserrat, sans-serif"}
                  color={Colors.WHITE}
                  fontSize="16px"
                  sx={{
                    transition: "color 0.5s ease",

                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                >
                  Zo's Menu
                </Typography>
                <IconButton onClick={handleMenuClick}>
                  {menuzos ? (
                    <KeyboardArrowUpIcon
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "white" }} />
                  )}
                </IconButton>
              </Box>
              <Menu
                anchorEl={menuzos}
                open={Boolean(menuzos)}
                onClose={handleFoodMenuClose}
                MenuListProps={{
                  "aria-labelledby": "menu-button",
                }}
                sx={{
                  ".MuiPaper-root": {
                    backgroundColor: Colors.BUTTON_COLOR, // Blue background for the menu
                    color: Colors.WHITE, // White text color
                    minWidth: "120px", // Optional: control the minimum width of the menu
                  },
                }}
              >
                <MenuItem onClick={() => window.open(VegPdfMenu, "_blank")}>
                  <ListItemIcon>
                    <FastfoodIcon style={{ color: "White" }} />{" "}
                  </ListItemIcon>{" "}
                  Vegetarian
                </MenuItem>
                <Box
                  margin={"4px auto"}
                  width={"80%"}
                  border={"1px solid white"}
                ></Box>

                <MenuItem onClick={() => window.open(NonVegPdfMenu, "_blank")}>
                  <ListItemIcon>
                    <RestaurantIcon style={{ color: "White" }} />
                  </ListItemIcon>
                  Non - Vegetarian
                </MenuItem>
                <Box
                  margin={"4px auto"}
                  width={"80%"}
                  border={"1px solid white"}
                ></Box>
                <MenuItem onClick={() => window.open(Juice, "_blank")}>
                  <ListItemIcon>
                    <BrunchDiningIcon style={{ color: "White" }} />
                  </ListItemIcon>
                  Refreshing Delights
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <Box onClick={handleBookNowClick} style={{ cursor: "pointer" }}>
              <Button
                variant="outlined"
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  minWidth: "110px",
                  fontWeight: "600",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background: disabledRoutes.includes(currentRoute)
                    ? "#CCCCCC"
                    : "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",

                  color: Colors.WHITE,
                  "&:hover": {
                    background: Colors.WHITE,
                    color: Colors.BUTTON_COLOR,
                    border: "2px solid #15B5FC",
                  },
                  fontFamily: "brandonText",
                }}
                disabled={disabledRoutes.includes(currentRoute)}
              >
                Book Now
              </Button>
            </Box>
            <IconButton
              style={{
                maxWidth: "60px",
                position: "fixed",
                bottom: "10%",
                right: "2%",
                background: isHovered ? Colors.WHITE : "#075E54", // Change color on hover
                color: isHovered ? "#075E54" : "white",
                borderRadius: "50%",
                height: "60px",
                width: "100%",
                border: `2px solid #075E54`,
              }}
              onClick={handleButtonClick}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? (
                <WhatsAppIcon
                  style={{
                    color: "#075E54",
                  }}
                />
              ) : (
                <WhatsAppIcon
                  style={{
                    color: Colors.WHITE,
                  }}
                />
              )}
            </IconButton>
            {isLoggedIn ? (
              <Box>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openvalue ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openvalue ? "true" : undefined}
                >
                  {/* <Avatar
                    sx={{ width: 40, height: 40, color: Colors.WHITE }}
                    alt="Remy Sharp"
                    // src={headerProfileLogo}
                  >
                    {/* {user ? user.name?.charAt(0).toUpperCase() : "U"} */}
                  {/* </Avatar> */}

                  <PersonIcon
                    sx={{ width: 40, height: 40, color: Colors.WHITE }}
                  />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openvalue}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      width: 200, // Adjust the width as needed
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={handleChange}
                    sx={{
                      "&:hover": {
                        backgroundColor: Colors.BUTTON_COLOR, // Change background color on hover
                        color: Colors.WHITE,
                      },
                      fontFamily: "brandonText",
                      fontSize: "16px",
                    }}
                  >
                    <Avatar
                      style={{
                        color: Colors.BUTTON_COLOR,
                        background: "white",
                      }}
                    />{" "}
                    My Profile
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      color: "#f44336",
                      padding: "10px 20px",
                      "&:hover": {
                        backgroundColor: Colors.BUTTON_COLOR, // Change background color on hover
                        color: Colors.WHITE,
                      },
                      fontFamily: "brandonText",
                      fontSize: "16px",
                    }}
                  >
                    <ListItemIcon>
                      <Logout
                        style={{
                          color: Colors.BUTTON_COLOR,
                          background: "white",
                          fontFamily: "brandonText",
                        }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  minWidth: "110px",
                  fontWeight: "600",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                  color: Colors.WHITE,
                  ":hover": {
                    background: Colors.WHITE,
                    color: Colors.BUTTON_COLOR,
                    border: "2px solid #15B5FC",
                  },
                  display: isLoggedIn ? "none" : "flex",
                  fontFamily: "brandonText",
                }}
                onClick={handleOpen}
              >
                Login
              </Button>
            )}{" "}
          </Stack>
        </Stack>

        <LoginForm handleClose={handleClose} open={open} />

        <BookingParantmodal
          open={openModal}
          handleClose={handleCloseModal}
          handleConfirmReset={handleConfirmLogout}
          text="Are you sure to log out?"
        />
      </AppContainer>
    </Box>
  );
}
