import {
  Box,
  Button,
  Divider,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import AppContainer from "../../CommonComponents/AppContainer";
import Colors from "../../CommonComponents/Colors";
import CustomTextField from "../../CommonComponents/CustomTextField";
import DropDownComponent from "../../CommonComponents/DropdownComponent";
import EnquiryApi from "../../api/EnquiryApi";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import ModalComponent from "../../CommonComponents/CustomDateCalender/ModalComponent";
import OverlayText from "../../CommonComponents/CustomDateCalender/OverLayText";
import YouTubeIcon from "@mui/icons-material/YouTube";
import banner from "./pexels-pavel-danilyuk-8381923.jpg";
import styled from "@emotion/styled";

const StyledIframe = styled.iframe`
  overflow-y: hidden;
  @media (min-width: 300px) {
    width: 340px;
    height: 340px;
    margin: auto;
  }
  @media (min-width: 414px) {
    width: 370px;
    height: 340px;
    margin: auto;
  }
  @media (min-width: 540px) {
    width: 490px;
    height: 340px;
    margin: auto;
  }

  @media (min-width: 768px) {
    width: 700px;
    height: 440px;
    margin: auto;
  }
  @media (min-width: 820px) {
    width: 730px;
    height: 440px;
    margin: auto;
  }
  @media (min-width: 912px) {
    width: 830px;
    height: 440px;
    margin: auto;
  }

  @media (min-width: 992px) {
    width: 840px;
    height: 440px;
    margin: auto;
  }
  width: 100%;
  max-width: 1400px;

  justify-content: center;
  display: flex;
  border-radius: 20px;
`;

const DropDownData = [
  {
    value: "Cross Fit",
    label: "Cross Fit",
  },
  {
    value: "Cafeteria",
    label: "Cafeteria",
  },
  {
    value: "Party Center",
    label: "Party Center",
  },
  {
    value: "turf",
    label: "Turf",
  },
  {
    value: "play station",
    label: "Play Station",
  },
  {
    value: "Badminton",
    label: "Badminton",
  },
  {
    value: "cricket Net",
    label: "Cricket Net",
  },
  {
    value: "Board Game",
    label: "Board Game",
  },
  {
    value: "bowling Machine",
    label: "Bowling Machine",
  },
];

export default function ContactUs() {
  const user = localStorage.getItem("user");
  const userData = user && JSON.parse(user);
  const [name, setName] = useState(userData ? userData["name"] : "");
  const [email, setEmail] = useState(userData ? userData["email"] : "");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    userData ? userData["phone"] : ""
  );
  const [successMessage, setSuccessMessage] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidType, setIsValidType] = useState<boolean>(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const handleNameChange = (event: any) => {
    setName(event);
    setIsValidName(false);
  };
  const handleEmailChange = (event: any) => {
    setEmail(event);
    setIsValidEmail(false);
  };

  const validateEmail = (input: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(input);
    return isValid;
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setType(event?.target.value);
    setIsValidType(false);
  };

  const handlePhoneChange = (event: any) => {
    setPhoneNumber(event);
    setIsValidPhone(false);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event?.target.value);
  };

  const successNotification = () => {
    setSuccessMessage(false);
  };

  const validatePhone = (input: any) => {
    const phonePattern = /^\d{10}$/;
    const isValid = phonePattern.test(input);
    return isValid;
  };

  const onSubmit = async (event: any) => {
    event?.preventDefault();

    if (!name) {
      setIsValidName(true);
    }
    if (!email) {
      setIsValidEmail(true);
    }
    if (!phoneNumber) {
      setIsValidPhone(true);
    }

    if (!type) {
      setIsValidType(true);
    }
    if (!validateEmail(email)) {
      setIsValidEmail(true);
      return;
    }
    if (!validatePhone(phoneNumber)) {
      setIsValidPhone(true);
      return;
    }
    if (name && email && phoneNumber && type) {
      const data = {
        name: name,
        email: email,
        type: type,
        phoneNumber: phoneNumber,
        message: message,
      };
      try {
        const response = await EnquiryApi.createEnquiry({
          userName: data.name,
          userEmail: data.email,
          enquiryMessage: data.message,
          phoneNumber: data.phoneNumber,
          projectType: data.type,
        });
        if (response) {
          setSuccessMessage(true);
          setName("");
          setEmail("");
          setPhoneNumber("");
          setType("");
          setMessage("");
        }
      } catch (err: any) {
        alert(err.message);
      }
    }
  };

  const BannerImage = styled.img`
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 30px;
  `;
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const TextOverlay = styled(Typography)`
    position: absolute;
    bottom: 20%; /* Aligns text to the bottom */
    left: 5%; /* Aligns text to the left */
    padding: 20px; /* Optional: Add some padding for better appearance */
    color: #ffffff; /* Optional: Text color for contrast */
  `;

  return (
    <>
      <OverlayText />
      <div
        style={{
          overflowY: "hidden",
        }}
      >
        <Box
          bgcolor={"rgb(231 231 231)"}
          sx={{
            borderBottomRightRadius: "30px",
            borderBottomLeftRadius: "30px",
          }}
        >
          <Box
            position="relative"
            padding={{ xs: "15px", sm: "15px", md: "30px" }}
          >
            <BannerImage src={banner} alt="banner" />
            <TextOverlay
              fontFamily="Montserrat, sans-serif"
              fontSize={{ xs: "32px", sm: "32px", md: "48px" }}
              fontWeight={700}
            >
              Contact Us
            </TextOverlay>
          </Box>
        </Box>
        <Box
          padding={{ xs: "0", sm: "0", md: "0 30px" }}
          marginTop={{ xs: "2rem", sm: "2rem", md: "2rem" }}
          sx={{
            overflowX: "hidden",
          }}
        >
          <AppContainer padding={0} maxWidth={1200}>
            <Stack
              style={{
                marginBottom: "20px",
                marginTop: "0px",
              }}
              justifyContent={"space-between"}
              spacing={10}
              gap={{ xs: "1rem", sm: "1rem", md: "5rem" }}
              direction={{
                xs: "column-reverse",
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              }}
              paddingBottom={{ xs: "30px", sm: "60px", md: "60px", lg: "0px" }}
            >
              <Box
                width={{ xs: "100%", sm: "100%", md: "100%" }}
                flexDirection={"column"}
                display={"flex"}
                marginLeft={{ xs: "-14px", sm: "0px", md: "0px", lg: "0px" }}
                sx={{
                  paddingTop: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                }}
              >
                <Box padding={{ xs: "30px", sm: "30px", md: 0 }}>
                  <Typography
                    fontSize={{ xs: "32px", sm: "32px", md: "50px" }}
                    sx={{
                      fontWeight: "bold",
                      paddingTop: "12px",
                      color: "black",
                    }}
                    fontFamily={"Montserrat, sans-serif"}
                    textAlign={{ xs: "center", sm: "center", md: "start" }}
                  >
                    We are always ready to help you and answer your questions{" "}
                  </Typography>

                  <Box
                    marginTop={"2rem"}
                    mb={"1rem"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection={{ xs: "column", sm: "row", md: "row" }}
                  >
                    <Box
                      alignItems={{ xs: "center", sm: "start", md: "start" }}
                      display={"flex"}
                      flexDirection={"column"}
                      textAlign={{ xs: "center", sm: "start", md: "start" }}
                      gap={"1rem"}
                    >
                      <Typography
                        fontWeight={700}
                        fontSize={{ xs: "16px", sm: "18px", md: "24px" }}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        Call Center
                      </Typography>
                      <Typography
                        fontSize={"16px"}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        <a
                          style={{
                            color: "black",
                          }}
                          href="tel:7094460944"
                        >
                          {" "}
                          +91 7094460944
                        </a>
                        <br />
                        <a
                          style={{
                            color: "black",
                          }}
                          href="tel:91088 83555"
                        >
                          {" "}
                          +91 91088 83555
                        </a>{" "}
                      </Typography>
                    </Box>

                    <Box
                      alignItems={{ xs: "center", sm: "start", md: "start" }}
                      display={"flex"}
                      flexDirection={"column"}
                      textAlign={{ xs: "center", sm: "start", md: "start" }}
                      gap={"1rem"}
                    >
                      <Typography
                        fontWeight={700}
                        fontSize={{ xs: "16px", sm: "18px", md: "24px" }}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        Our Location
                      </Typography>
                      <Typography
                        fontSize={{ xs: "15px", md: "16px" }}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        39/6 KCP Thottam,
                        <br /> Kumalan Kuttai, Erode, <br />
                        Tamil Nadu - 638011
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      // height: "1px",
                      background: "black",
                      width: "100%",
                      color: "black",
                    }}
                  />
                  <Box
                    flexDirection={{ xs: "column", sm: "row", md: "row" }}
                    marginTop={"1rem"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={{ xs: "10px", md: "1rem" }}
                      alignItems={{ xs: "center", sm: "start", md: "start" }}
                    >
                      <Typography
                        fontWeight={700}
                        fontSize={{ xs: "16px", sm: "18px", md: "24px" }}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        Email
                      </Typography>
                      <Box>
                        <Typography
                          fontSize={{ xs: "15px", md: "16px" }}
                          fontFamily={"Montserrat, sans-serif"}
                        >
                          <a
                            style={{
                              color: "black",
                            }}
                            href="mailto:playzo.bookings@gmail.com"
                          >
                            playzo.bookings@gmail.com
                          </a>{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      alignItems={{ xs: "center", sm: "start", md: "start" }}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={{ xs: "10px", md: "1rem" }}
                    >
                      <Typography
                        pt={{ xs: "15px", md: "0px" }}
                        fontWeight={700}
                        fontSize={{ xs: "16px", sm: "18px", md: "24px" }}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        Social Network
                      </Typography>
                      <Stack
                        justifyContent={{
                          xs: "flex-start",
                          md: "space-between",
                        }}
                        alignItems={"center"}
                        direction="row"
                        spacing={2}
                      >
                        <Link
                          target="_blank"
                          to={"https://www.facebook.com/PlayZo33"}
                        >
                          <FacebookIcon
                            sx={{
                              width: "25px",
                              height: "30px",
                              transition: "color 0.3s ease",
                              color: Colors.BUTTON_COLOR,
                            }}
                          />
                        </Link>
                        <Link
                          target="_blank"
                          to={"https://www.instagram.com/playzo_33/"}
                        >
                          <InstagramIcon
                            sx={{
                              width: "25px",
                              height: "30px",
                              transition: "color 0.3s ease",

                              color: Colors.BUTTON_COLOR,
                            }}
                          />
                        </Link>
                        <Link
                          target="_blank"
                          to={
                            "https://www.youtube.com/results?search_query=PLAYZO+33"
                          }
                        >
                          <YouTubeIcon
                            sx={{
                              width: "25px",
                              height: "30px",
                              transition: "color 0.3s ease",
                              color: Colors.BUTTON_COLOR,
                            }}
                          />
                        </Link>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                // style={{ marginBottom: "50px" }}
                pt={{ xs: "0px", sm: "0px", md: "0px", lg: "40px" }}
                width={{ xs: "100%", sm: "100%", md: "100%" }}
                margin={"0 !important"}
              >
                <form onSubmit={onSubmit}>
                  <Stack
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "-50px",
                      },
                    }}
                    margin={{
                      xs: "0 auto",
                      sm: "0 auto",
                      md: "0 auto",
                      lg: "0 auto",
                    }}
                    border={{
                      xs: "1px solid #d4ebf2",
                      sm: "1px solid #d4ebf2",
                      md: "1px solid #d4ebf2",
                      lg: "1px solid #d4ebf2",
                    }}
                    width={{ xs: "80%", sm: "88%", md: "90%", lg: "100%" }}
                    borderRadius={{
                      xs: "20px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    }}
                    padding={{
                      xs: "21px 16px",
                      sm: "21px 16px",
                      md: "21px 16px",
                      lg: "10px 21px",
                    }}
                    direction="column"
                    spacing={3}
                    style={{
                      backgroundColor: "rgb(231 231 231)",
                      // boxShadow: "1px 7px 11px 2px dimgrey",
                    }}
                  >
                    <Box
                      margin={0}
                      padding={{ xs: "20px", sm: "20px", md: "30px" }}
                    >
                      <Typography
                        fontSize={"24px"}
                        fontFamily={"Montserrat, sans-serif"}
                        fontWeight={700}
                      >
                        {" "}
                        Get in Touch
                      </Typography>

                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"15px"}
                      >
                        <CustomTextField
                          variant="standard"
                          sx={{ maxWidth: 700, borderRadius: "8px" }}
                          label="Name"
                          required={false}
                          placeholder="Enter your name"
                          value={name}
                          onChange={handleNameChange}
                          error={!!isValidName}
                          helperText={
                            isValidName ? "Please provide valid Name" : ""
                          }
                        />
                        <CustomTextField
                          variant="standard"
                          sx={{ maxWidth: 700, borderRadius: "8px" }}
                          label="Email"
                          required={false}
                          placeholder="Enter your email address"
                          value={email}
                          onChange={handleEmailChange}
                          error={!!isValidEmail}
                          helperText={
                            isValidEmail ? "Please provide valid Email" : ""
                          }
                        />
                        <DropDownComponent
                          label="What service are you interested in"
                          options={DropDownData}
                          placeHolder="Select your service"
                          value={type}
                          onChange={handleTypeChange}
                          error={!!isValidType}
                          helperText={
                            isValidType ? "Please provide valid Service" : ""
                          }
                        />
                        <CustomTextField
                          variant="standard"
                          sx={{ maxWidth: 700, borderRadius: "8px" }}
                          label="Phone Number"
                          required={false}
                          placeholder="Enter your phone number"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          type="number"
                          error={!!isValidPhone}
                          helperText={
                            isValidPhone
                              ? "Please provide valid phone number"
                              : ""
                          }
                        />
                        <Box mb={"2rem"}>
                          <Typography
                            marginBottom={"10px"}
                            fontSize={"16px"}
                            fontWeight={"600"}
                            color={Colors.BLACK}
                            fontFamily={"Montserrat, sans-serif"}
                          >
                            Message
                          </Typography>

                          <TextField
                            variant="standard"
                            placeholder="Enter your message..."
                            sx={{
                              fontFamily: "Montserrat, sans-serif",
                              borderRadius: "8px",
                              width: "100%",
                              maxWidth: "700px",
                              "& .MuiInputBase-input": {
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: 600,
                                padding: "10px",
                              },
                              "& .MuiInputLabel-root": {
                                fontFamily: "Montserrat, sans-serif",
                              },
                              "& .MuiFormHelperText-root": {
                                color: "red",
                                fontFamily: "Montserrat, sans-serif !important",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid lightgray",
                              },
                            }}
                            id="outlined-basic"
                            value={message}
                            onChange={handleMessageChange}
                            name="passage"
                            FormHelperTextProps={{
                              sx: { fontFamily: "Montserrat, sans-serif" },
                            }}
                          />
                        </Box>
                      </Box>

                      <Button
                        sx={{
                          width: "100%",
                          borderRadius: "30px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: 400,
                          padding: "10px",
                          maxWidth: 250,
                          border: "1px solid #15B5FC",
                          background:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                          fontFamily: "Montserrat, sans-serif",
                          color: Colors.WHITE,
                          ":hover": {
                            background: Colors.WHITE,
                            color: Colors.BUTTON_COLOR,
                            border: "1px solid #15B5FC",
                          },
                        }}
                        variant="outlined"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </form>
                <ModalComponent
                  open={successMessage}
                  handleClose={successNotification}
                  text="Your Enquiry is Successfully added"
                  headingText="Enquiry Confirmation"
                />
              </Box>
            </Stack>
          </AppContainer>
        </Box>
        <Box margin={{ xs: 0, sm: 0, md: "2rem 0" }}>
          <StyledIframe
            style={{
              width: "100%",
            }}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.383003662614!2d77.6973392!3d11.3341512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6d17503b1dad5cfb!2sPlayzo%2033!5e0!3m2!1sen!2sus!4v1643151588496!5m2!1sen!2sus"
          ></StyledIframe>
        </Box>
      </div>
    </>
  );
}
