import "./Gallery.css"; // Import your styles from a separate CSS file

import {
  default as cafe,
  default as image4,
} from "./SpinerImages/cafeteria_service1.avif";

import Net from "./cricketNet.jpg";
import badminton from "./SpinerImages/badcourt_1.avif";
import board from "./boardgames22.jpg";
import court from "./court.avif";
import image2 from "./ground_turf.jpg";
import image3 from "./ServiceImages/Turf_service.jpg";
import launch from "./../../BookingService/Playzo.png";
import playstation from "./ServiceImages/playstation_service.webp";

const Gallery = () => {
  return (
    <div className="gallery">
      <span
        style={{
          top: "40%",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={launch}
          alt="Banner"
        />
      </span>
      <span>
        <img src={image3} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2019,Oct</h1>
          <p className="paratext">Playzo Launch</p>
        </div>
      </span>
      <span>
        <img src={playstation} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2020,Jul</h1>
          <p className="paratext">Playzo Turf 2.0 launch</p>
        </div>
      </span>
      <span>
        <img src={image2} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2020,Dec</h1>
          <p className="paratext">Playzo Turf 2.0 & 3.0 launch</p>
        </div>
      </span>

      <span>
        <img src={badminton} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2021,Dec</h1>
          <p className="paratext">Playzo 4.0 Cafeteria Launch</p>
        </div>
      </span>

      <span>
        <img src={court} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2023,Feb</h1>
          <p className="paratext">Playzo 5.0 Badminton Court Launch</p>
        </div>
      </span>
      <span>
        <img src={board} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2023,Jul</h1>
          <p className="playzopara">Playzo Board Games launch</p>
        </div>
      </span>
      <span>
        <img src={image4} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2023,Aug</h1>
          <p className="paratext">Playzo 4.0 Renovation of Cafeteria</p>
        </div>
      </span>
      <span>
        <img src={Net} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2022,Aug</h1>
          <p className="paratext">
            Playzo CricketNet and Bowling Machine launch
          </p>
        </div>
      </span>
    </div>
  );
};

export default Gallery;
