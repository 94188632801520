/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Button, Divider, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import AppContainer from "../CommonComponents/AppContainer";
import Colors from "../CommonComponents/Colors";
import Juice from "../assets/juices & dinner.pdf";
import NonVegPdfMenu from "../assets/Zo's cafe non-veg.pdf";
import OTPModal from "../pages/login/OTPModal";
import OverlayText from "../CommonComponents/CustomDateCalender/OverLayText";
import SliderContainer from "../pages/aboutUs/AboutSlider";
import VegContainPage from "./VegRelatedPage";
import VegPdfMenu from "../assets/Zo's-cafe-veg.pdf";
import assets from "../assets";
import book from "./book.svg";
import bowlingImage from "../assets/Bowling";
import cafeteriaImage from "../assets/Cafeteriya";
import cricketNetImage from "../assets/CricketNet";
import foodImage2 from "./bg-non2.webp";
import partycenterImage from "../assets/Partycenter";
import play from "./play.svg";
import search from "./search.svg";
import styled from "styled-components";
import videoFile from "./Playing2.mp4";

const StyledImage = styled.img`
  @media (min-width: 300px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 768px) {
    width: 225px;
    height: 80px;
  }
  @media (min-width: 820px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 900px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 1200px) {
    width: 340px;
    height: 100px;
  }
`;
const StyledImage1 = styled.img`
  @media (min-width: 300px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 768px) {
    width: 45px;
    height: 45px;
  }
  @media (min-width: 820px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 900px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 1200px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledImage2 = styled.img`

@media (min-width: 300px) {
  width: 320px;
  height: 325px;
}
@media (min-width: 414px) {
  width: 315px;
  height: 325px;
}

@media (min-width: 768px) {
  width: 325px;
  height: 325px;
}
@media (min-width: 820px) {
  width: 325px;
  height: 325px;
}

@media (min-width: 912px) {
  width: 388px;
  height: 370px;
}
@media (min-width: 1024px) {
  width: 388px;
  height: 370px;
}


@media (min-width: 1200px) {
  width: 345px;
  height: 345px;

  @media (max-width: 1280px) {
    width: 290px;
    height: 345px;
}
`;
const StyledVideo = styled.video`
  @media (min-width: 300px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 414px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 540px) {
    width: 490px;
    height: auto;
    margin: auto;
  }

  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 820px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 912px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 992px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 1000px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 1200px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const SlideshowContainer = styled(Box)`
  width: 100%;
  max-width: 600px;
`;

const Slide = styled(Link)`
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out;
`;

const StyledImage3 = styled.img`
  border-radius: 10px;
  height: 350px;
  object-fit: cover;
`;

const {
  "pexels-guduru-ajay-bhargav-863988.jpg": Image3,
  "pexels-pixabay-209977.jog": Image4,
  "pexels-pixabay-46798.jpg": Image5,
  "pexels-pixabay-269630.jpg": boardGames,
  "pexels-vazhnik-7864443.jpg": playstation,
  "pexels-thiago-kai-9381737.jpg": turf,
  // "playzo.mp4": videoFile,
  "Playzo.png": logo,
  "instagram-ico.png": insta,
  "278453372_302010395428882_1782198866577334663_n.jpg": pic1,
  "292237898_605074144217428_6740391706468899652_n.jpg": pic2,
  "296017376_1229442621218307_3144906300765873571_n.jpg": pic3,
  "307209848_504498594836255_6289868240102465405_n.jpg": pic4,
} = assets;

const { "party1.webp": PartyCenterSlide1 } = partycenterImage;
const { "bowling2.jpg": BowlingSlide2 } = bowlingImage;

const { "crick2.jpg": CricketNetSlide2 } = cricketNetImage;

const { "cafe4.jpg": cafe } = cafeteriaImage;

const images = [Image3, Image4, Image5]; // List of images to loop through
const intervalTime = 5000; // Interval time in milliseconds

export default function HomePage() {
  const [showText, setShowText] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowText(true);
    }, 1000);

    const timer2 = setTimeout(() => {
      // Set showText to true for the second timer
      setInitialLoad(false); // Move this line here to set initialLoad to false after animation
    }, 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [initialLoad]);
  const [loading, setLoading] = useState(true); // Set initial loading state to true

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setLoading(false); // After timeout, set loading state to false
    }, 3000); // Adjust timeout duration as needed

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, []);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const [openOtp, setOpenOtp] = useState(false);

  const { id } = useParams<{ id: string }>();

  const handleClose = () => {
    setOpenOtp(false);
  };

  useEffect(() => {
    // Regular expression to check if id is an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the URL contains an email address
    if (id && emailRegex.test(id)) {
      setOpenOtp(true);
    }
  }, [id]);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch((error) => {
          console.log("Error attempting to play video:", error);
        });
      }
    };

    // Play video on initial load
    playVideo();

    // Play video on user interaction
    document.addEventListener("click", playVideo, { once: true });

    return () => {
      document.removeEventListener("click", playVideo);
    };
  }, []);

  const bookingflowItems = [
    {
      image: search,
      title: "REFRESH",
      description:
        "Need to refuel? A Taste of Delight Awaits! Whether you're looking for a quick snack or a sit-down meal, our Zo's Cafe offers a variety of tummy-friendly options that are as delicious as they are gentle on your stomach. Come visit us today and feel good about what you eat with a delightful dining experience. Zo's Cafe is the perfect place to relax, refuel, and recharge! Check the menu right now!",
    },
    {
      image: book,

      title: "RESERVE",
      description:
        "Book your sport today and feel the stress melt away with every game! Select your preferred date and time to confirm your booking. Let us provide the perfect venue for your next sports activity, whether it’s a casual game, a full-fledged competition, or a fun day out with friends and family. We can’t wait to welcome you! Get ready for some fun!",
    },
    {
      image: play,

      title: "REPEAT",
      description:
        "The more you play, the more you save! We believe that our repeat customers deserve the best, Our special offers are just one way we show our appreciation for your continued support. Keep playing, keep saving, and enjoy more every time you visit!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const Instaimages = [
    {
      src: pic1,
      link: "https://www.instagram.com/p/CcZ6CBzJoOD/?igsh=MWFjOGltaWJ5NXBraA%3D%3D",
    },
    {
      src: pic2,
      link: "https://www.instagram.com/p/CfwI533r_-c/?igsh=MTFjbnIxcjY0bmZ1dw%3D%3D",
    },
    {
      src: pic3,
      link: "https://www.instagram.com/p/Cgln7h6JH7Y/?igsh=MWZrMDg2cncxYXV2dg%3D%3D",
    },
    {
      src: pic4,
      link: "https://www.instagram.com/p/CimdLmRJBfn/?igsh=MXBtem1iNm15cmhxZg%3D%3D",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === Instaimages.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  // Calculate the scroll position based on the currentIndex
  const imageWidth = 200; // Width of each image
  const gapBetweenImages = 35; // Gap between images
  const totalScrollWidth =
    Instaimages.length * (imageWidth + gapBetweenImages) - gapBetweenImages;
  const scrollPosition = currentIndex * (imageWidth + gapBetweenImages);

  useEffect(() => {
    // Attempt to play the video programmatically
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Handle the error (autoplay was prevented)
        console.log("Autoplay was prevented:", error);
      });
    }
  }, []);

  useEffect(() => {
    // Ensure the video plays automatically on load
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <OverlayText />

      <Box width="100%">
        <StyledVideo ref={videoRef} autoPlay loop muted controls={false}>
          <source src={videoFile} type="video/mp4" />
        </StyledVideo>

        <AppContainer
          mb={{ xs: "0px", sm: "0px", md: "50px", lg: "0px" }}
          padding={0}
          maxWidth={1450}
        >
          <Box
            display={"flex"}
            gap={"15px"}
            justifyContent={"space-between"}
            alignItems={{
              xs: "flex-start",
              sm: "center",
              md: "flex-start",
            }} // Use flex-start to align items at the top
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            padding={{ xs: "2rem", sm: "2rem", md: "4rem 20px" }}
          >
            {bookingflowItems.map((item, index) => (
              <Box
                maxWidth={450}
                key={index}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-start"} // Ensure items align to the start
                gap={"15px"}
                sx={{ height: { xs: "auto", md: "200px" } }} // Set a fixed height for uniformity
              >
                <img
                  style={{
                    maxWidth: 45,
                  }}
                  src={item.image}
                  alt="flows"
                />
                <Typography
                  fontSize={"24px"}
                  fontFamily={"Montserrat, sans-serif"}
                  color={"#060b1e"}
                  fontWeight={600}
                  textAlign={"center"} // Center align text
                >
                  {item.title}
                </Typography>
                <Typography
                  fontWeight={300}
                  textAlign={"center"}
                  fontSize={"16px"}
                  fontFamily={"Montserrat, sans-serif"}
                  color={"#060b1e"}
                >
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </AppContainer>

        <Box margin={"0"} padding={{ xs: 0, sm: 0, md: "4rem 0" }}>
          <SliderContainer />
        </Box>

        <Box
          padding={{ xs: "20px", sm: "20px", md: "0 50px" }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Box maxWidth={850}>
            <Typography
              fontSize={{ xs: "24px", sm: "24px", md: "26px", lg: "32px" }}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"lucoCleanBold"}
            >
              Flavors that
              <Typography
                fontSize={{ xs: "26px", sm: "26px", md: "26px", lg: "50px" }}
                fontWeight={700}
                color={Colors.BLACK}
                textAlign={"start"}
                textTransform={"none"}
                fontFamily={"lucoCleanBold"}
                sx={{
                  background:
                    "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text", // This is for non-webkit browsers (optional)
                  textFillColor: "transparent", // This is for non-webkit browsers (optional)
                }}
              >
                ignite your senses.
              </Typography>
            </Typography>
            <Typography
              mt={"12px"}
              fontSize={"18px"}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"Montserrat, sans-serif"}
            >
              Food is an art form, combining flavors, textures, and aromas to
              create unforgettable experiences together.
            </Typography>

            <Box mt={"20px"} display={"flex"} gap={"10px"} flexWrap={"wrap"}>
              <Button
                onClick={() => window.open(VegPdfMenu, "_blank")}
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  minWidth: "110px",
                  fontWeight: "600",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",

                  color: Colors.WHITE,
                  "&:hover": {
                    border: "2px solid #15B5FC",
                  },

                  fontFamily: "brandonText",
                }}
              >
                Vegetarian
              </Button>

              <Button
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  minWidth: "110px",
                  fontWeight: "600",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",

                  color: Colors.WHITE,
                  "&:hover": {
                    border: "2px solid #15B5FC",
                  },

                  fontFamily: "brandonText",
                }}
                onClick={() => window.open(NonVegPdfMenu, "_blank")}
              >
                Non - Vegetarian
              </Button>
              {/* <Divider /> */}
              <Button
                onClick={() => window.open(Juice, "_blank")}
                variant="outlined"
                sx={{
                  padding: "8px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  minWidth: "110px",
                  fontWeight: "600",
                  border: "2px solid #15B5FC",
                  borderRadius: "30px",
                  letterSpacing: "1.6px",
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",

                  color: Colors.WHITE,
                  "&:hover": {
                    border: "2px solid #15B5FC",
                  },

                  fontFamily: "brandonText",
                }}
              >
                Refreshing Delights
              </Button>
            </Box>
          </Box>
          <Box
            overflow={"hidden"}
            display={{ xs: "none", sm: "none", md: "block" }}
          >
            <img
              src={foodImage2}
              style={{
                animation: "rotate 10s linear infinite", // 5-second rotation loop
              }}
              width={"450px"}
              alt="food-section"
            />
            <style>{`
              @keyframes rotate {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
            `}</style>
          </Box>
        </Box>
        {/* <Box overflow={"hidden"}>
          <Box
            padding={{ xs: "20px", sm: "20px", md: "20px 50px" }}
            display={"flex"}
          >
            <Typography
              fontSize={"22px"}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"lucoCleanBold"}
            >
              Popular
            </Typography>
            <Typography
              fontSize={"22px"}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"lucoCleanBold"}
              sx={{
                background:
                  "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text", // This is for non-webkit browsers (optional)
                textFillColor: "transparent", // This is for non-webkit browsers (optional)
              }}
            >
              Dishes
            </Typography>
          </Box>
          <VegContainPage />
        </Box> */}

        <Box width="100%">
          <Box padding={{ xs: "20px", sm: "20px", md: "0 50px" }}>
            <Typography
              fontSize={{ xs: "24px", sm: "24px", md: "26px", lg: "32px" }}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"lucoCleanBold"}
            >
              Playzo33{" "}
              <Typography
                fontSize={{ xs: "26px", sm: "26px", md: "26px", lg: "50px" }}
                fontWeight={700}
                color={Colors.BLACK}
                textAlign={"start"}
                textTransform={"none"}
                fontFamily={"lucoCleanBold"}
                sx={{
                  background:
                    "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text", // This is for non-webkit browsers (optional)
                  textFillColor: "transparent", // This is for non-webkit browsers (optional)
                }}
              >
                Insta Feeds
              </Typography>
            </Typography>
          </Box>

          <Box
            bgcolor={"aliceblue"}
            marginTop={"2rem"}
            display={{ xs: "none", sm: "none", md: "flex" }}
            justifyContent={"space-between"}
            padding={"20px 50px"}
            flexWrap={"wrap"}
            gap={{ xs: "20px", sm: "20px", md: "20px", lg: 0 }}
          >
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CcZ6CBzJoOD/?igsh=MWFjOGltaWJ5NXBraA%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic1}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CfwI533r_-c/?igsh=MTFjbnIxcjY0bmZ1dw%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic2}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/Cgln7h6JH7Y/?igsh=MWZrMDg2cncxYXV2dg%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic3}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CimdLmRJBfn/?igsh=MXBtem1iNm15cmhxZg%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic4}
                alt=""
              />
            </Link>
          </Box>

          <Box
            mt={"30px"}
            ml={{ xs: 0, sm: "0px", md: "46px" }}
            pb={"30px"}
            display={{ xs: "flex", sm: "flex", md: "none" }}
            sx={{
              overflowX: "hidden",
              overflowY: "hidden",
              width: "100%",
            }}
          >
            <Box
              gap={"35px"}
              sx={{
                display: "flex",
                transform: `translateX(-${scrollPosition}px)`,
                transition: "transform 0.5s ease-out",
                width: `${totalScrollWidth}px`,
                maxWidth: "100%",
                marginLeft: "22px",
              }}
            >
              {Instaimages.map((item, index) => (
                <Box key={index} width={"86%"}>
                  <a href={item.link}>
                    <img
                      src={item.src}
                      height={"100%"}
                      width={"250px"}
                      alt={`Slide ${index}`}
                      style={{
                        borderRadius: "30px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {id && openOtp ? (
        <OTPModal
          open={true}
          handleClose={handleClose}
          email={id}
          setOpenForgetModal={handleClose}
        />
      ) : (
        ""
      )}
    </>
  );
}
