import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import AppContainer from "../AppContainer";
import Colors from "../Colors";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LoginForm from "../../pages/login/LoginForm";
import YouTubeIcon from "@mui/icons-material/YouTube";
import assets from "../../assets";
import booknow from "./play.svg";
import booknowWhite from "./play copy.svg";
import routes from "../../routes/routes";
import { styled } from "@mui/system";

const { "Playzo (1).svg": logo } = assets;

const StyledLink = styled(Link)({
  color: Colors.WHITE,
  fontSize: "15px",
  fontWeight: 600,
  textUnderlineOffset: "5px",
  textDecoration: "none",
  fontFamily: "brandonText",
  textTransform: "capitalize",

  "&:hover": {
    color: Colors.WHITE,
  },
});

export default function Footer() {
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerTop = footerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        setIsFooterVisible(footerTop <= windowHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call it initially to set the state correctly

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const location = useLocation();

  const hiddenRoutes = [
    routes.BOOKING_SERVICE,
    routes.TURF_BOOKING,
    routes.PLAYSTATION_BOOKING,
    routes.BADMINTON_BOOKING,
    routes.BOARDGAME_BOOKING,
    routes.BOWLINGMACHINE_BOOKING,
    routes.CRICKETNET_BOOKING,
    "/payment-booking",
  ];

  interface User {
    name?: string;
  }

  const [user, setUser] = useState<User | null>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [Modalopen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);

  const handleBookNowClick = () => {
    if (isLoggedIn) {
      // If logged in, navigate to the booking service page
      navigate(routes.BOOKING_SERVICE);
    } else {
      // If not logged in, open the login modal
      setModalOpen(true);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const parsedUserData = userData && JSON.parse(userData);

    if (parsedUserData) {
      setUser(parsedUserData);
      setIsLoggedIn(true); // Set to true if there is user data
    } else {
      setUser(null);
      setIsLoggedIn(false); // Set to false if there is no user data
    }
  }, []);

  return (
    <Box
      borderBottom={`1px solid ${Colors.SHADOW}`}
      bgcolor={Colors.BLACK}
      top={0}
      zIndex={1000}
      paddingRight={"24px"}
      paddingY={4}
      //height={"456px"}
    >
      <AppContainer p={0}>
        <Box
          maxWidth={1400}
          padding={"0 30px"}
          marginX="auto"
          color={Colors.WHITE}
        >
          <Link
            style={
              {
                marginBottom: 1,
              } as any
            }
            to={routes.ROOT}
          ></Link>

          {isFooterVisible ? (
            <IconButton
              onClick={handleBookNowClick}
              sx={{
                maxWidth: "115px",
                position: "fixed",
                bottom: "10.5%",
                right: "3%",
                transition: "background-color 0.3s ease",
                animation: "slideUpDown 6s infinite ease-in-out", // Add animation here
                display: hiddenRoutes.includes(location.pathname)
                  ? "none"
                  : { xs: "block", sm: "block", md: "none" },
                "@keyframes slideUpDown": {
                  "0%, 100%": {
                    transform: "translateY(5px)", // No movement
                  },
                  "50%": {
                    transform: "translateY(-30px)", // Slide up
                  },
                },
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              <img
                width={60}
                src={isFooterVisible ? booknowWhite : booknow}
                alt="book"
              />
              Book Now
            </IconButton>
          ) : (
            <IconButton
              onClick={handleBookNowClick}
              sx={{
                maxWidth: "115px",
                position: "fixed",
                bottom: "10.5%",
                right: "3%",
                transition: "background-color 0.3s ease",
                animation: "slideUpDown 6s infinite ease-in-out", // Add animation here
                display: hiddenRoutes.includes(location.pathname)
                  ? "none"
                  : { xs: "block", sm: "block", md: "none" },
                "@keyframes slideUpDown": {
                  "0%, 100%": {
                    transform: "translateY(5px)", // No movement
                  },
                  "50%": {
                    transform: "translateY(-30px)", // Slide up
                  },
                },
                color: "black",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              <img
                width={60}
                src={isFooterVisible ? booknowWhite : booknow}
                alt="book"
              />
              Book Now
            </IconButton>
          )}

          <Stack
            ref={footerRef}
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
            gap={"20px"}
            flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
          >
            <Box maxWidth={{ xs: "100%", sm: "100%", md: 300 }}>
              <Stack direction="column" spacing={4}>
                <Link to={routes.ROOT}>
                  <img src={logo} width={200} height={50} alt="22" />
                </Link>
                <Typography
                  fontSize="15px"
                  fontWeight={"600"}
                  ml={{
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "10px !important",
                  }}
                  marginTop={"15px !important"}
                  fontFamily={"brandonText"}
                  color={"white"}
                >
                  At Playzo33, we extend beyond being a turf booking platform
                  we're a community-driven destination designed for sports
                  enthusiasts and those seeking recreation and entertainment.
                </Typography>
              </Stack>
            </Box>

            <Divider
              variant="fullWidth"
              color={Colors.WHITE}
              sx={{
                marginTop: "15px",
                marginBottom: "10px",
                display: { xs: "block", sm: "block", md: "none" },
                width: "90%",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={{
                xs: "space-between",
                sm: "space-between",
                md: "space-evenly",
              }}
              gap={"20px"}
              flexWrap={"wrap"}
              width={"100%"}
            >
              <Stack direction="column" margin={"0 !important"} spacing={2}>
                <Typography
                  fontSize={"15px"}
                  fontWeight={"800"}
                  letterSpacing={"1.6px"}
                  textTransform={"uppercase"}
                  color={Colors.BUTTON_COLOR}
                  fontFamily={"brandonText"}
                >
                  Company
                </Typography>
                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.ABOUTUS}
                >
                  About
                </StyledLink>

                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.CONTACTUS}
                >
                  Contact
                </StyledLink>
              </Stack>
              <Stack margin={"0 !important"} direction="column" spacing={2}>
                <Typography
                  fontSize={"15px"}
                  fontWeight={"800"}
                  letterSpacing={"1.6px"}
                  textTransform={"uppercase"}
                  color={Colors.BUTTON_COLOR}
                  fontFamily={"brandonText"}
                >
                  Information
                </Typography>
                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.PRIVACY_POLICY}
                >
                  Privacy Policy
                </StyledLink>
                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    lineHeight: 1,
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.TERMS_AND_CONDITIONS}
                >
                  Terms And Conditons
                </StyledLink>
                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    lineHeight: 1,
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.COOKIES_PAGE}
                >
                  Cookies Policy
                </StyledLink>
                <StyledLink
                  sx={{
                    // fontSize: "16px",
                    lineHeight: 1,
                    transition: "color 0.5s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                  to={routes.CAREERS_PAGE}
                >
                  Careers
                </StyledLink>
              </Stack>
              <Divider
                variant="fullWidth"
                color={Colors.WHITE}
                sx={{
                  marginTop: "15px",
                  marginBottom: "10px",
                  display: { xs: "block", sm: "none" },
                  width: "90%",
                }}
              />

              <Stack margin={"0 !important"}>
                <Typography
                  fontSize={"15px"}
                  fontWeight={"800"}
                  letterSpacing={"1.6px"}
                  textTransform={"uppercase"}
                  color={Colors.BUTTON_COLOR}
                  fontFamily={"brandonText"}
                >
                  Contact us
                </Typography>

                <Stack spacing={2}>
                  <Typography
                    fontFamily={"brandonText"}
                    fontSize="15px"
                    pt={"12px"}
                    fontWeight={600}
                    textTransform={"uppercase"}
                    color={"white"}
                  >
                    39/6 KCP Thottam, Kumalan Kuttai, Erode,
                    <br />
                    Tamil Nadu - 638011{" "}
                  </Typography>
                  <Typography
                    textTransform={"uppercase"}
                    fontFamily={"brandonText"}
                    fontSize="15px"
                    fontWeight={600}
                    color={"white"}
                  >
                    <a
                      style={{
                        color: "white",
                      }}
                      href="tel:7094460944"
                    >
                      +91 7094460944
                    </a>{" "}
                    <br />
                    <a
                      style={{
                        color: "white",
                      }}
                      href="tel:9108883555"
                    >
                      +91 91088 83555
                    </a>
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>

          <Divider
            variant="fullWidth"
            color={Colors.WHITE}
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              display: { xs: "block", sm: "none" },
            }}
          />
          <Divider
            variant="fullWidth"
            color={Colors.WHITE}
            sx={{
              marginTop: "100px",
              marginBottom: "30px",
              display: { xs: "none", sm: "block" },
            }}
          />

          <Stack
            marginTop={{ xs: "0px", sm: "0px", md: "0px", lg: "48px" }}
            display={{ xs: "flex", sm: "none" }}
            justifyContent={"center"}
            alignItems={"center"}
            direction="row"
            spacing={2}
          >
            <Link target="_blank" to={"https://www.facebook.com/PlayZo33"}>
              <FacebookIcon
                sx={{
                  color: Colors.WHITE,
                  width: "25px",
                  height: "30px",
                  transition: "color 0.3s ease",
                  ":hover": {
                    color: Colors.BUTTON_COLOR,
                  },
                }}
              />
            </Link>
            <Link target="_blank" to={"https://www.instagram.com/playzo_33/"}>
              <InstagramIcon
                sx={{
                  color: Colors.WHITE,
                  width: "25px",
                  height: "30px",
                  transition: "color 0.3s ease",
                  ":hover": {
                    color: Colors.BUTTON_COLOR,
                  },
                }}
              />
            </Link>
            <Link
              target="_blank"
              to={"https://www.youtube.com/results?search_query=PLAYZO+33"}
            >
              <YouTubeIcon
                sx={{
                  color: Colors.WHITE,
                  width: "25px",
                  height: "30px",
                  transition: "color 0.3s ease",
                  ":hover": {
                    color: Colors.BUTTON_COLOR,
                  },
                }}
              />
            </Link>
          </Stack>

          <Divider
            variant="fullWidth"
            color={Colors.WHITE}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              display: { xs: "block", sm: "none" },
            }}
          />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontSize="12px"
              fontWeight={600}
              fontFamily={"brandonText"}
              color={"white"}
            >
              Copyright © 2024 Playzo33 | All Rights Reserved
            </Typography>
            <Stack
              display={{ xs: "none", sm: "block" }}
              direction="row"
              spacing={2}
            >
              <Link target="_blank" to={"https://www.facebook.com/PlayZo33"}>
                <FacebookIcon
                  sx={{
                    color: Colors.WHITE,
                    width: "25px",
                    height: "30px",
                    transition: "color 0.3s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                />
              </Link>
              <Link target="_blank" to={"https://www.instagram.com/playzo_33/"}>
                <InstagramIcon
                  sx={{
                    color: Colors.WHITE,
                    width: "25px",
                    height: "30px",
                    transition: "color 0.3s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                />
              </Link>
              <Link
                target="_blank"
                to={"https://www.youtube.com/results?search_query=PLAYZO+33"}
              >
                <YouTubeIcon
                  sx={{
                    color: Colors.WHITE,
                    width: "25px",
                    height: "30px",
                    transition: "color 0.3s ease",
                    ":hover": {
                      color: Colors.BUTTON_COLOR,
                    },
                  }}
                />
              </Link>
            </Stack>
          </Stack>
        </Box>
      </AppContainer>

      <LoginForm handleClose={handleClose} open={Modalopen} />
    </Box>
  );
}
